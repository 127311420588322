/* --------------------------------------------------
 Header Style
-------------------------------------------------- */

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  &.center {
	  background-position: center;
  }
}
.menu-btn {
  position: relative;
  top: auto;
  right: 0;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  .icon-bar {
    display: block;
    height: 2px;
    width: 22px;
    border-radius: 1px;
    background-color: white;
    transition: all .2s;
    &:first-child,
    &:last-child {
      transform: rotate(0);
    }
    &:nth-child(2) {
      opacity: 1;
    }
    +.icon-bar {
      margin-top: 5px;
    }
  }

}
.menu-btn {
  /*z-index: 9999;*/
  display: block;
  border: none;
  /*background-color: $white;*/
  &:focus {
    outline: none;
  }
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  height: 100%;
  width: 100%;
  background-color: rgba(36, 36, 36, .9);
  text-align: center;
  .menu-btn {
    float: right;
    height: 20px;
    margin-top: 38px;
    margin-right: 15px;
    cursor: pointer;
    .icon-bar {
      &:first-child {
        @include css3-prefix(transform, rotate(45deg));
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        margin-top: -9px;
        @include css3-prefix(transform, rotate(-45deg));
      }
    }
  }
  .navbar-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    @include css3-prefix(transform, translate(-50%, -50%));
    display: inline-block;
    width: 100%;
    margin-top: 30px;
  }
  .nav-item {
    a {
      display: inline-block;
      color: $white !important;
      font-size: 60px;
      @media (max-width: 640px) {
        font-size: 40px;
        line-height: normal;
      }
      &:hover {
        color: $secondary-color !important;
      }
    }
    &.active {
      a {
        color: $secondary-color !important;
      }
    }
	&.spacer {
		border-top: 6px solid white;
		width: 200px;
		margin: 10px auto 25px auto;
	}
	.small {
		font-size: 30px;
	}
  }
}
.page-scroll {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 40px;
  left: 0;
  text-align: center;
  a {
    display: inline-block;
    height: 60px;
    max-width: 60px;
    width: 100%;
    padding: 18px 0;
    border: 2px solid $secondary-color;
    border-radius: 100%;
    color: $white;
    font-size: 15px;
    font-weight: 800;
    font-family: $avenirltstd-heavy;
    letter-spacing: 1px;
    text-align: center;
    text-transform: lowercase;
    &:hover {
      background-color: $secondary-color;
      text-decoration: none;
    }

  }
  &::after {
    position: absolute;
    top: auto;
    bottom: -40px;
    left: 50%;
    display: inline-block;
    height: 40px;
    width: 2px;
    margin-left: -2px;
    border: 1px solid $secondary-color;
    content: '';
    }
}
