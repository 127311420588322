/* --------------------------------------------------
  Buttons styling
-------------------------------------------------- */

.btn {
  padding: 13px;
  border: 3px solid $black;
  border-radius: 0;
  color: $black;
  font-size: 20px;
  font-weight: 800;
  font-family: $avenirltstd-black;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    background: $black;
    color: $white;
  }
  @media (max-width: 992px) {
    padding: 10px;
    font-size: 14px;
  }
}
.btn-white {
  border-color: $white;
  color: $white;
  &:hover,
  &:focus,
  &:active {
    background: $white;
    color: $secondary-color;
  }
}
.btn-green {
  border-color: $dark-green;
  color: $dark-green;
  &:hover,
  &:focus,
  &:active {
    background: $dark-green;
    color: $white;
  }
}
.btn-darkgreen {
  border-color: $secondary-color;
  background-color: $secondary-color;
  color: $white;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: $secondary-color;
  }
}

