/*

  Fonts


*/

$avenirltstd-light: 'Avenirltstd Light';
$avenirltstd-black: 'Avenirltstd Black';
$avenirltstd-heavy: 'Avenirltstd Heavy';


$url-avenirltstd-light: url('../assets/fonts/Avenir/AvenirLTStd-Light.otf'), url('../assets/fonts/Avenir/AvenirLTStd-Light.woff') format('woff');
$url-avenirltstd-black: url('../assets/fonts/Avenir/AvenirLTStd-Black.woff');
$url-avenirltstd-heavy: url('../assets/fonts/Avenir/AvenirLTStd-Heavy.otf'), url('../assets/fonts/Avenir/AvenirLTStd-Heavy.woff') format('woff');


@font-face {
  font-family: $avenirltstd-light;
  src: $url-avenirltstd-light;
}
@font-face {
  font-family: $avenirltstd-black;
  src: $url-avenirltstd-black;
}

@font-face {
  font-family: $avenirltstd-heavy;
  src: $url-avenirltstd-heavy;
}
