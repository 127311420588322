/* --------------------------------------------------
  Footer Style
-------------------------------------------------- */
.footer {
  padding: 65px 0;
  background-color: $footer-gray;
  color: $white;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .footer-wrap {
    /*max-width: 1400px;*/
    width: 100%;
    /*margin: 0 auto;*/
    padding: 0 5%;
  }
}
.left-footer {
  max-width: 600px;
  font-size: 30px;
  line-height: 45px;
  font-family: $avenirltstd-black;
  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  p {
    @media (min-width: 992px) {
      margin-bottom: 40px;
    }
  }
  .btn {
    text-transform: capitalize;
  }
}
.right-footer {
  @media (min-width: 768px) {
    padding-left: 15%;
    border-left: 2px solid rgba($white, .5);
  }
  img {
    @media (max-width: 767px) {
      max-width: 200px;
    }
  }
  p,
  address {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  address {
    max-width: 220px;
  }
  ul {
    display: inline-block;
    width: 100%;
    list-style-type: none;
    li {
      float: left;
      display: inline-block;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      img {
        @media (max-width: 1200px) {
          width: 40px;
        }
      }
    }
  }
}

