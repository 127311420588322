/* --------------------------------------------------
   Components
-------------------------------------------------- */

.w-750 {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.dark-green {
  background-color: $secondary-color;
  color: $white;
}

.background-one,
.background-two {
  position: relative;
  &::after {
    position: absolute;
    top: 170px;
    right: 0;
    left: auto;
    display: inline-block;
    height: 603px;
    width: 503px;
    background-image: url('../assets/images/bg-1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    @media (max-width: 992px) {
      height: 557px;
      width: 230px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.background-two {
  &::after {
    top: 210px;
    left: 0;
    background-image: url('../assets/images/bg.png');
    @media (max-width: 767px) {
      display: none;
    }
  }
  &.case-bg-two {
    &::after {
      top: 130px;
      background-image: url('../assets/images/case-bg.png');
      @media (max-width: 992px) {
        top: 280px;
      }
    }
  }
}
.gray-image,
.green-image {
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    left: 0%;
    z-index: -1;
    display: inline-block;
    /*height: 250px;*/
    height: 330px;
    width: 100%;
    background-color: #E4E4E4;
    content: '';
    @include css3-prefix(transform, translateY(-50%));
    @media (max-width: 1200px) {
      height: 250px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
}
.green-image {
  &::before {
    background-color: $secondary-color;
  }
}
.each-casestudy,
.each-product {
  &:nth-child(2n) {
    .gray-image,
    .green-image {
      &::before {
        right: 0%;
        left: auto;
      }
    }
  }
}
.work-list {
  display: inline-block;
  width: 100%;
  @media (max-width: 992px) {
    margin-top: 15px;
  }
  li {
    display: inline-block;
    padding: 0 15px 0 13px;
    border-right: 2px solid $secondary-color;
    line-height: 20px;
    &:last-child {
      border-right: none;
    }
    a {
      color: $primary-color;
      font-size: 24px;
      &:hover {
        text-decoration: none;
      }
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
}
