/* --------------------------------------------------
  3.0 Demo 1
-------------------------------------------------- */

.growing-wrap {
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .green-heading {
    max-width: 590px;
    width: 100%;
    margin: 0 auto 20px;
  }
}


.services-wrap {
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
}
.service-card-wrap {
  display: inline-block;
  width: 100%;
  margin: 30px 0;
  .col-md-4 {
    img {
      @include css3-prefix(transition, all ease .3s);
      /*transition: all ease .3s;*/
      &:hover {
        margin-top: -10px;
        padding-bottom: 10px;
      }
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.home-case-wrapper,
.product-wrapper {
  padding: 30px 0;
  @media (max-width: 992px) {
    max-width: 720px;
    margin: 0 auto;
    padding: 25px 15px;
  }
}

.each-casestudy,
.each-product {
  padding: 50px 0;
  @include css3-sufix(display, flex);
  @include css3-prefix(flex-wrap, wrap);
  @media (max-width: 992px) {
    padding: 25px 0 0;
  }
  .casestudy-image,
  .image-wrapper {
    width: 55%;
    /*margin-right: 5%;*/
    text-align: center;
    @include css3-prefix(align-self, center);
    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  /*.casestudy-image {
    padding-left: 3%;
    @media (max-width: 992px) {
      padding-left: 0;
    }
  }*/
  .casestudy-image {
    width: 50%;
    margin-right: 5%;
    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
    }
    img {
      float: right;
      padding: 0 30px;
      @media (max-width: 992px) {
        float: none;
        padding: 0;
      }
    }
  }
  .image-wrapper {
    width: 50%;
    /*margin-right: 5%;*/
    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    /*img {
      @media (min-width: 993px) {
        float: left;
        padding-right: 10%;
      }
    }*/
    &.image-centered {
      img {
        float: none;
      }
    }
  }
  .casestudy-content,
  .grid-content {
    width: 50%;
    padding: 0 5%;
    @include css3-prefix(align-self, center);
    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
      padding: 25px 0 0;
    }
    p {
      margin-bottom: 25px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
  .casestudy-content {
    width: 45%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &:nth-child(2n) {
    @include css3-prefix(flex-direction, row-reverse);
    .casestudy-image,
    .image-wrapper {
      margin-right: 0%;
      /*margin-left: 5%;*/
      /*@media (max-width: 992px) {
        margin-left: 0;
      }*/
      /*text-align: right;*/
    }
    .casestudy-image {
      img {
        float: left;
        @media (max-width: 992px) {
          float: none;
        }
      }
    }
    .image-wrapper {
      img {
        /*@media (min-width: 993px) {
          float: right;
          padding: 0 0 0 10%;
        }*/
      }
      &.image-centered {
        img {
          float: none;
        }
      }
    }
  }
}
.each-product {
  &:nth-child(2n) {
    .image-full {
      img {
        float: right;
        padding-right: 0;
        padding-left: 80px;
        @media (max-width: 992px) {
          float: none;
          padding: 0;
        }
      }
    }
  }
}
.case-study-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 992px) {
    padding-top: 30px;
    padding-bottom: 30px;
}
  .each-product {
    padding: 0;
  }
}
.image-full {
  img {
    width: 100%;
    padding-right: 80px;
    @media (max-width: 992px) {
      padding-right: 0;
    }
  }
}
.testimonial-wrapper {
  // position: relative;
  // display: inline-block;
  width: 100%;
  border-top: 7px solid $secondary-color;
  border-bottom: 7px solid $secondary-color;
  background: url(/images/bnr.png) no-repeat;
  background-position: center;
  background-size: cover;
  .testimonial-content {

	padding: 3em 0;
    // position: absolute;
    /*top: 80px;*/
    // top: 50%;
    //left: 0;
    //width: 100%;
    /** @include css3-prefix(transform , translateY(-50%)); **/
    blockquote {
      max-width: 90%;
      margin: 0 auto;
      /*margin-left: 10%;*/
      font-size: 35px;
      line-height: 55px;
      letter-spacing: 3px;
      text-align: center;
      @media (max-width: 992px) {
        font-size: 26px;
        line-height: 40px;
      }
      @media (max-width: 767px) {
        /*margin-left: 0;*/
        font-size: 20px;
        line-height: normal;
      }
      @media (max-width: 480px) {
        font-size: 20px;
      }
      .blockquote-footer {
        color: $dark-green;
        font-family: $avenirltstd-black;
      }
	  footer {margin-top: 20px;  }
    }
  }
}
.clients {
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .row {
    @media (min-width: 768px) {
      padding: 30px 0;
    }
    >div {
      @media (max-width: 767px) {
        margin: 10px 0;
      }
    }
  }
}
.result-wrap {
  padding-top: 70px;
  .title-wrap {
    max-width: 1000px;
    margin: 0 auto;
    .service-title {
      margin-bottom: 15px;
    }
  }
  .row {
    padding: 0 0 30px;
    @media (min-width: 992px) {
      padding: 45px 0 60px;
    }
    .col-md-4 {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 992px) {
    padding-top: 30px;
  }
}
.more-work {
  padding: 70px 0;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
  .green-heading {
    color: $green-ab;
    font-size: 40px;
    line-height: 60px;
    @media (max-width: 992px) {
      font-size: 30px;
      letter-spacing: normal;
    }
  }
}

