/* --------------------------------------------------
  2.0 Mixins

  * Font-Face
  * Font-size
  * Clearfix
  * Opacity
  * Vendor Prefix
  * Centre a block element
  * Vertical centring a block element
  * Psudo elements
  * Placeholders
  * Truncate Text
  * MediaQueries
  * Keyframes and animation
  * Transitions
  * Transform

-------------------------------------------------- */


/* Keyframes and animation */
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}


/* USAGE =====
@include keyframes(slide-down) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  @include animation('slide-down 5s 3');
}
*/

@mixin css3-prefix($property, $value) {
-webkit-#{$property}: #{$value};
-khtml-#{$property}: #{$value};
-moz-#{$property}: #{$value};
-ms-#{$property}: #{$value};
-o-#{$property}: #{$value};
#{$property}: #{$value};
}
@mixin css3-sufix($property, $value) {
#{$property}: -webkit-#{$value};
#{$property}: -khtml-#{$value};
#{$property}: -moz-#{$value};
#{$property}: -ms-#{$value};
#{$property}: -o-#{$value};
#{$property}: #{$value};
}





