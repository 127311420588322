/* --------------------------------------------------
  Banner style
-------------------------------------------------- */
.banner-wrap {
  position: relative;
  /*padding-top: 70%;*/
  .banner-image {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;

	&.center {
		background-position: center;
	}
  }
  .banner-content {
    position: absolute;
    top: 25%;
    left: 50%;
    max-width: 705px;
    width: 100%;
    @include css3-prefix(transform, translateX(-50%));
    @media (max-width: 992px) {
      top: 30%;
      left: auto;
      max-width: inherit;
      width: auto;
      padding-right: 15px;
      @include css3-prefix(transform, translateX(0));
    }
  }
}
.casestudy-banner {
  /*max-height: 550px;*/
  margin-bottom: 55px;
  padding-top: 125px;
  background-image: none;
  @media (max-width: 992px) {
    padding-top: 80px;
  }
  .each-casestudy {
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 992px) {
      max-width: 720px;
      margin: 0 auto;
      padding: 15px;
    }
    .casestudy-image {
      width: 55%;
      margin-right: 0;
      /*padding: 0 3% 0 0;*/
      @media (max-width: 992px) {
        width: 100%;
        /*padding: 0;*/
      }
      img {
        margin-bottom: -70px;
        @media (max-width: 1200px) {
          margin-bottom: 0;
        }
      }
    }
  }
  .case-category {
    color: $white;
    &::before {
      background-image: url('../assets/images/case_icon_white.png');
    }
  }
  .case-title {
    margin-bottom: 15px;
    line-height: 50px;
    @media (max-width: 992px) {
      line-height: normal;
    }
  }
}
