/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */



/* ---- Colors ---- */

$white: #FFF;
$black: #000;
$green: #00B876;
$green-ab: #2AB573;
$dark-green: #1E7C58;
$gray:  #C0C0C0;
$image-gray:  #E4E4E4;
$footer-gray: #333;
$primary-color: #666;
$secondary-color: $green;
