/* --------------------------------------------------
  3.0 Demo 1
-------------------------------------------------- */

.banner-title {
  color: $white;
  font-size: 70px;
  line-height: 80px;
  font-weight: 800;
  font-family: $avenirltstd-black;
  letter-spacing: 5px;
  word-spacing: 5px;
  @media (max-width: 1200px) {
    font-size: 50px;
    line-height: normal;
  }
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: normal;
  }
  &::after {
    display: block;
    height: 10px;
    width: 100px;
    margin-top: 5px;
    background-color: $secondary-color;
    content: '';
  }
  &.clear::after {display: none !important;}
}
.banner-subheading {
  color: $gray;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  word-break: break-word;
  @media (max-width: 480px) {
    font-size: 14px;
    letter-spacing: 1px;
  }

  &.light {
	  color: white;
  }
}
.green-heading {
  color: $secondary-color;
  font-size: 50px;
  font-weight: bold;
  font-family: $avenirltstd-black;
  letter-spacing: 4px;
  @media (max-width: 992px) {
    font-size: 36px;
    letter-spacing: normal;
  }
  @media (max-width: 480px) {
    font-size: 30px;
  }
}
.white-title {
  font-size: 50px;
  line-height: 40px;
  font-family: $avenirltstd-black;
  letter-spacing: 4px;
  @media (max-width: 992px) {
    font-size: 40px;
    letter-spacing: normal;
  }
  @media (max-width: 480px) {
    font-size: 30px;
  }
}
.service-title {
  max-width: 300px;
  width: 100%;
  margin: 15px auto 0;
  font-size: 35px;
  line-height: 45px;
  font-weight: 800;
  font-family: $avenirltstd-light;
  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
  }
}
.case-category {
  /*color: $secondary-color;*/
  color: $green-ab;
  font-weight: 800;
  font-family: $avenirltstd-black;
  letter-spacing: 1px;
  text-transform: uppercase;
  &::before {
    display: inline-block;
    height: 15px;
    width: 20px;
    background-image: url('../assets/images/casestudy_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }
}
.case-title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 800;
  font-family: $avenirltstd-black;
  letter-spacing: 1px;
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: normal;
  }
}
.number-big {
  font-size: 80px;
  font-style: italic;
  @media (max-width: 992px) {
    font-size: 50px;
  }
}
.result-text {
  font-size: 30px;
  line-height: normal;
  font-family: $avenirltstd-black;
  letter-spacing: normal;
  @media (max-width: 992px) {
    font-size: 24px;
  }
}
.result-title {
  font-size: 40px;
  font-family: $avenirltstd-black;
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: normal;
  }
}
p.tag-line {
  margin-top: 10px;
  color: $primary-color;
  font-size: 15px !important;
  line-height: normal;
  font-family: $avenirltstd-black;
  text-transform: uppercase;
}
.footer-question {
  color: $secondary-color;
  font-size: 30px;
  font-weight: 800;
  @media (max-width: 992px) {
    font-size: 20px;
  }
}
