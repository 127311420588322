/* --------------------------------------------------
  Global styling goes here
-------------------------------------------------- */

img {
  max-width: 100%;
}
body {
  color: $primary-color;
  font-size: 20px;
  font-family: $avenirltstd-light;
  letter-spacing: 2px;
  @media (max-width: 767px) {
    letter-spacing: normal;
  }
}
p {
  line-height: 40px;
  letter-spacing: 2px;
  @media (max-width: 992px) {
    line-height: 30px;
    letter-spacing: normal;
  }
}
ul {
  margin: 0;
  padding: 0;
}
*:focus,
:focus {
  outline: none;
}
